// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Slider-module-cursor_qCabe {\n  position: absolute;\n  border-radius: 50%;\n  box-sizing: border-box;\n  cursor: move;\n}\n", "",{"version":3,"sources":["webpack://./Sources/Interaction/UI/Slider/Slider.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,sBAAsB;EACtB,YAAY;AACd","sourcesContent":[".cursor {\n  position: absolute;\n  border-radius: 50%;\n  box-sizing: border-box;\n  cursor: move;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cursor": "Slider-module-cursor_qCabe"
};
export default ___CSS_LOADER_EXPORT___;
