export const SlabTypes = {
  MIN: 0,
  MAX: 1,
  MEAN: 2,
  SUM: 3,
};

export default {
  SlabTypes,
};
